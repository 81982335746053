.Tooltip {
  background-color: #444;
  color: white;
  font-size: 90%;
  padding: 4px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  width: max-content;
  max-width: calc(100vw - 10px);
  z-index: 999999;
}
