/* .rstcustom__rowContents {
  flex-direction: row-reverse !important;
} */

.form-checkbox[aria-checked="mixed"] {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlPSJ3aGl0ZSI+DQogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMTggMTJINiIgLz4NCjwvc3ZnPg==);
}

.rstcustom__toolbarButton {
  display: flex;
  align-items: center;
}

.rstcustom__rowWrapper:hover {
  opacity: 1 !important;
}

.rstcustom__rowLabel {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}

.rstcustom__row.disabled .rstcustom__rowLabel {
  opacity: 0.5 !important;
}

.rstcustom__row.disabled input[type="checkbox"] {
  pointer-events: none;
  opacity: 0.5 !important;
}

.rstcustom__row.disabled input[type="checkbox"]:checked {
  pointer-events: all;
}

.rstcustom__row.disabled button[data-type="settings"] {
  pointer-events: none;
  opacity: 0.5;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover
  > .react-contexify__item__content {
  background-color: rgb(46, 115, 182) !important;
}

.rstcustom__rowCancelPad::before {
  display: none !important;
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0;
  height: 0px;
  background-color: green !important;
}

.rstcustom__rowCancelPad {
  height: 0px !important;
}

.checkbox-loading-animation {
  /* opacity: 0.9; */
  position: absolute;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    -30deg,
    rgb(0, 91, 179),
    #c54141,
    #5182eb,
    #ee7752,
    #242cbb
  );
  background-size: 1000% 1000%;
  animation: animated-checkbox 3s ease infinite;
}

@keyframes animated-checkbox {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.spinner_7NYg {
  animation: spinner_0KQs 1.2s cubic-bezier(0.52, 0.6, 0.25, 0.99) infinite;
}
@keyframes spinner_0KQs {
  0% {
    r: 0;
    /* opacity: 1; */
  }
  100% {
    r: 11px;
    /* opacity: 0; */
  }
}

.spinner_V8m1 {
  transform-origin: center;
  animation: spinner_zKoa 2s linear infinite;
  transform: scale(0.4);
}
.spinner_V8m1 circle {
  stroke-linecap: round;
  animation: spinner_YpZS 1.5s ease-in-out infinite;
}

@keyframes spinner_zKoa {
  100% {
    transform: rotate(360deg) scale(0.4);
    color: #2643a2;
  }
}

@keyframes spinner_YpZS {
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }
  95%,
  100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
}

.spinner_ZCsl {
  animation: spinner_qV4G 1.2s cubic-bezier(0.52, 0.6, 0.25, 0.99) infinite;
}
.spinner_gaIW {
  animation-delay: 0.6s;
}
@keyframes spinner_qV4G {
  0% {
    r: 0;
    opacity: 1;
  }
  100% {
    r: 11px;
    opacity: 0;
  }
}

.rst__node {
  height: 36px !important;
  @apply text-sm p-0 font-normal bg-transparent;
  line-height: normal !important;
}

.rst__node .rst__rowWrapper {
  @apply py-1 px-0;
}

.rst__node .rst__rowTitle {
  @apply font-normal;
}

.rst__moveHandle {
  border-radius: 0.25rem 0 0 0.25rem !important;
  box-shadow: none !important;
  width: 32px !important;
  flex: none;
}

.rst__rowContents {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  box-shadow: none !important;
  flex: 1 !important;
  display: flex;
}

.rst__row {
  max-width: 400px !important;
  overflow: hidden;
  flex: 1;
  background-color: transparent !important;
}

.rst__rowLabel {
  @apply truncate;
  flex: 1 !important;
}
