.center-ish::before {
  content: "";
  flex: 1;
  min-height: 80px;
}
.center-ish::after {
  content: "";
  flex: 3;
  min-height: 50px;
}

html.survey {
  height: 100%;
}

.survey body {
  /* min-height: 100vh; */
  height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  .survey body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

.mapboxgl-map.mouse-not-allowed
  .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: not-allowed;
}

.SurveyAppLayout .mapboxgl-ctrl-top-left {
  /* top: 4px; */
}

.SurveyAppLayout .mapboxgl-ctrl-scale {
  font-weight: 500;
  font-size: 14px;
}
