.indeterminantSlider {
  position: relative;
  width: 100%;
  height: 5px;
  overflow-x: hidden;
}

.indeterminantSlider .line {
  position: absolute;

  width: 150%;
  height: 5px;
}

.indeterminantSlider .subline {
  position: absolute;
  height: 5px;
}
.indeterminantSlider .inc {
  animation: increase 2s infinite;
}
.indeterminantSlider .dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
