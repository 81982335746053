input[type="number"].form-element-number-input {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type="number"].form-element-number-input::-webkit-inner-spin-button,
input[type="number"].form-element-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
